import React, {useEffect} from 'react';
import {navigate} from 'gatsby'

import Layout from "../components/layout"
import GatedContent from "../components/gatedcontent"


import { onAuthUIStateChange } from '@aws-amplify/ui-components';

import menulist from "../content/headermenu.json"


const MainIndex = () => {

	// Home page redirect after login
	useEffect(()=>{
		return onAuthUIStateChange((nextAuthState, authData) => {
			if (nextAuthState === "signedin") {
				navigate("/"+menulist[0].url);
			}
		});
	},[])

	return (
		<Layout activePath='' pageTitle="Login" privatePage={true}>
			<GatedContent>
				<p class="bannertext font-size-large">
					IAMNET Documents Portal
				</p>
			</GatedContent>
		</Layout>
	)
}


export default MainIndex

